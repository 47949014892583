import { getExamDetails } from '@/services/exameDetails'
import * as state from './state'
  
export const setIdExame = (value) => {
  state.examDetails.value.id_exam = value
}

export const setIdade = (value) => {
  state.examDetails.value.idade = value
}

export const setSexo = (value) => {
  state.examDetails.value.sexo = value
}

export const setTipoDenticao = (value) => {
  state.examDetails.value.tipo_denticao = value
}

export const setUser = (value) => {
  state.examDetails.value.user = value
}

export const fetchExamDetails = (examId) => {
  getExamDetails(examId)
    .then((res) => {
      setIdExame(res?.data.id_exam)
      setIdade(res?.data.idade)
      setSexo(res?.data.sexo)
      setTipoDenticao(res?.data.tipo_denticao)
      setUser(res?.data.user)
    })
    .catch(() => {
      console.log('error resquest')
    })
}