import { createApp } from "vue"
import App from "./App.vue"
import { createPinia } from "pinia"
import router from "./router"
import "@mdi/font/css/materialdesignicons.css"
import { icons } from './site/icons'

import { OhVueIcon, addIcons } from "oh-vue-icons"
addIcons(...icons)

// Vuetify
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"

// Importe o estilo do Vuetify
import "vuetify/dist/vuetify.min.css"

const app = createApp(App)

const vuetify = createVuetify({
  components,
  directives,
})

// Use o plugin Vuetify
app.use(vuetify)

app.component('v-icon', OhVueIcon)

const pinia = createPinia()
app.use(pinia)

app.use(router).mount("#app")
