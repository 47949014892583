import { computed, ref } from 'vue'
import * as state from './state'

export const getFindingsDetails = computed(() => {
  return state.findingsDetails.value
})

export const getTeethOut = computed(() => {
  const findings = state.findingsDetails.value
  const teethOut = Object.keys(findings)
    .filter(key => findings[key].fields.id_alteracao === 1)
    .map(key => findings[key].fields.num_dente)
  
  return JSON.parse(JSON.stringify(teethOut));
})

export const getTeethAlt = computed(() => {
  const teethAlt = ref([])
  state.findingsDetails.value.forEach(item => {
    if(item.fields.id_alteracao !== null && item.fields.num_dente !== null) {
      teethAlt.value.push(item.fields.num_dente)
    }
  })
  return teethAlt
})

//retorna a lista de achados agrupados por achado
export const groupFindingsOrder = computed(() => {
  const findingsOrder = {}

  Object.keys(state.dentais.value).forEach(data => {
    state.dentais.value[data].forEach(item => {
      if (!findingsOrder[item.id_alteracao]) {
        findingsOrder[item.id_alteracao] = {
          nm_alteracao: item.nm_alteracao,
          alteracoes: []
        }
      }
      findingsOrder[item.id_alteracao].alteracoes.push(item)
    })
  })

  return findingsOrder
})