import { defineStore } from "pinia"
import * as useActions from './actions'
import * as useGetters from './getters'
import * as useState from './state'

const useFindingsDetailsStore = defineStore('findings', () => ({
  ...useActions,
  ...useGetters,
  ...useState,
}))

export default useFindingsDetailsStore