<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col v-if="showDrawer" class="d-none d-lg-flex" cols="2">
        <NavigationDrawer />
      </v-col>

      <v-col :cols="showDrawer ? 10 : 12">
        <v-row no-gutters>
          <v-col>
            <div class="d-flex align-center justify-center mt-12">
              <span class="font-weight-bold text-h3-base"
                >Dashboard - Papiron</span
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import NavigationDrawer from "@/components/navigationDrawer.vue"
  export default {
    name: "DashboardPage",
    components: {
      NavigationDrawer,
    },
    data() {
      return {
        showDrawer: true,
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/style/main.scss";
</style>
