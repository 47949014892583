import axios from "axios"

const getMapa = () => {
  const url = `https://www.api-plataforma.machiron.com.br/mapa/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  return axios.get(url, config)
}

export { getMapa }