import axios from "axios"

// Composable para obter dados do usuário
export async function useGetUserData(token) {
  if (!token) {
    token = localStorage.getItem("jwtToken")
  }

  try {
    const response = await axios.get(
      "http://www.api-plataforma.machiron.com.br/registry/users/me",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.status === 200) {
      const userData = response.data

      localStorage.setItem("userEmail", userData.email)
      localStorage.setItem("userId", userData.id)

      return userData
    } else {
      console.error("Erro ao obter os dados do usuário")
      return null
    }
  } catch (error) {
    // Lidar com erros, como token JWT inválido
    console.error("Erro ao obter os dados do usuário:", error)
    throw new Error("Erro ao obter os dados do usuário")
  }
}
