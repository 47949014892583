<template>
  <v-container id="particles-js">
    <div class="d-flex justify-end align-center">
      <p class="text-body-1-base ml-6">Não tem uma conta ainda?</p>
      <v-btn
        variant="plain"
        class="text-body-2-base"
        color="#122167"
        @click="goRegister"
      >
        Criar agora
      </v-btn>
    </div>
    <div class="d-flex justify-center align-center">
      <v-card
        width="380"
        height="320"
        elevation="20"
        class="pa-6 py-4 box-card"
      >
        <div class="mt-6 text-center">
          <p class="text-h5-base font-weight-bold">Recuperação de senha</p>
          <p class="text-body-1-base" style="color: #9e9e9e">
            Insira seu e-mail
          </p>
        </div>
        <form class="mt-12" action="" @submit="checkLogin">
          <v-text-field
            label="E-mail"
            v-model="email"
            :rules="[emailRule]"
            clearable
            variant="solo"
            required
            @change="checkEmail"
            class="custom-background"
          ></v-text-field>
          <div class="d-flex align-center justify-start mt-2">
            <v-btn
              :disabled="!isValid"
              type="submit"
              color="#122167"
              size="large"
              variant="flat"
              ><p class="font-weight-bold text-white text-body-2-base">
                Enviar
              </p></v-btn
            >
          </div>
        </form>
      </v-card>
    </div>
  </v-container>
</template>
<script>
  export default {
    name: "ForgotPasswordForm",
    data() {
      return {
        email: "",
        emailValid: true,
      }
    },
    mounted() {
      const particlesJS = window.particlesJS
      particlesJS("particles-js", {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#122167",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#c8581b",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      })
    },
    methods: {
      checkEmail() {
        // eslint-disable-next-line no-extra-semi
        ;/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(this.email)
          ? (this.emailValid = true)
          : (this.emailValid = false)
      },
      checkUpperCase(str) {
        return /[A-Z]/.test(str) && /[0-9]/.test(str)
      },
      checkLogin(e) {
        e.preventDefault()
        const status = this.accounts.filter(
          (record) =>
            record.email === this.email && record.password === this.password
        )

        if (status.length > 0) {
          localStorage.setItem("userEmail", this.email)
          this.$router.push("/welcome")
        } else {
          document.querySelector(".errorLogin").classList.remove("disable")
          this.email = ""
          this.password = ""
          this.passwordValid = false
        }
      },
      goRegister() {
        this.$router.push("/register")
      },
    },

    computed: {
      isValid() {
        return this.emailValid && this.email
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/style/main.scss";
  #particles-js {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .box-card {
    position: absolute;
    margin-top: 75%;
  }
</style>
