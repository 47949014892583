<template>
  <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout" top>
    {{ snackbarMessage }}
  </v-snackbar>
</template>

<script>
  export default {
    data() {
      return {
        snackbar: false,
        snackbarMessage: "",
        snackbarColor: "success",
        timeout: 3000,
      }
    },
    methods: {
      showSuccess(message) {
        this.snackbarColor = "success"
        this.showSnackbar(message)
      },
      showError(message) {
        this.snackbarColor = "error"
        this.showSnackbar(message)
      },
      showSnackbar(message) {
        this.snackbarMessage = message
        this.snackbar = true
      },
    },
  }
</script>
