<script setup>
  import useExamStore from '@/stores/exam'

  const store = useExamStore()


</script>

<template>
  <div class="cmp-patient-data">
    <v-row no-gutters>
			<v-col cols="12" class="mb-2">
				<span class="cmp-patient-data__title">Paciente: </span>
				<span class="cmp-patient-data__description">Teste da usabilidade</span>
			</v-col>
			<v-col cols="6" class="mb-2">
				<span class="cmp-patient-data__title">Nascimento: </span>
				<span class="cmp-patient-data__description">07/10/2024 - 30 anos e 7 meses</span>
			</v-col>
			<v-col cols="6" class="mb-2">
				<span class="cmp-patient-data__title">Data: </span>
				<span class="cmp-patient-data__description">14/05/2024</span>
			</v-col>
			<v-col cols="2" class="mb-2">
				<span class="cmp-patient-data__title">Sexo: </span>
				<span class="cmp-patient-data__description">{{ store.mapSexo }}</span>
			</v-col>
			<v-col cols="4" class="mb-2">
				<span class="cmp-patient-data__title">OS: </span>
				<span class="cmp-patient-data__description">0SA.00000</span>
			</v-col>
			<v-col cols="6" class="mb-2">
				<span class="cmp-patient-data__title">Doutor: </span>
				<span class="cmp-patient-data__description">Não informado</span>
			</v-col>
		</v-row>
  </div>
</template>

<style scoped lang="scss">
  @import '_patient-data.scss';
</style>
