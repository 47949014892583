<template>
  <div class="d-flex flex-column box-container">
    <div class="text-center">
      <div class="mt-12">
        <img src="@/assets/logo-white.png" alt="MaChiron" style="width: 70%" />
      </div>
    </div>
    <div class="text-white mx-12 mt-12">
      <span class="font-weight-bold text-h4-base"
        >Bem-vindo à plataforma Machiron!</span
      >
      <p class="font-weight-regular text-h5-base">
        Utilizamos inteligência artificial e aprendizado de máquina aliados a
        uma expertise humana interdisciplinar para oferecer soluções em saúde.
      </p>
    </div>
    <div class="d-flex text-center justify-center space-box py-12">
      <img src="@/assets/logo_fapesp.png" alt="Fapesp" style="width: 30%" />

      <img
        src="@/assets/logo_finep.png"
        alt="Finep"
        style="width: 30%"
        class="mx-4"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: "machiron_container",
    data() {
      return {}
    },
    methods: {},
  }
</script>
<style lang="scss">
  @import "@/style/main.scss";
  .box-container {
    background-color: #122167;
    overflow-y: hidden !important;
    height: 105%;
  }
  .space-box {
    margin-top: 10%;
  }
</style>
