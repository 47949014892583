<template>
  <v-dialog
    v-model="internalDialogVisible"
    :max-width="maxWidth"
    @input="updateParentDialogVisible"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          variant="tonal"
          v-if="!loading"
          @click="saveChanges"
          color="primary"
          rounded
        >
          Salvar
        </v-btn>
        <v-btn variant="tonal" @click="cancelChanges" rounded v-if="!loading"
          >Cancelar</v-btn
        >
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      dialogVisible: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: "Dialog Title",
      },
      maxWidth: {
        type: String,
        default: "500px",
      },
    },
    data() {
      return {
        internalDialogVisible: this.dialogVisible,
        loading: false,
      }
    },
    watch: {
      dialogVisible(newVal) {
        this.internalDialogVisible = newVal
      },
    },
    methods: {
      updateParentDialogVisible(newVal) {
        this.$emit("update:dialogVisible", newVal)
      },
      async saveChanges() {
        try {
          this.loading = true

          await new Promise((resolve) => setTimeout(resolve, 2000))

          this.$emit("save")
        } finally {
          this.loading = false
        }
      },
      cancelChanges() {
        this.$emit("cancel")
      },
      openDialog() {
        // Lógica para abrir o diálogo
        this.$emit("update:dialogVisible", true)
      },
      closeDialog() {
        // Lógica para fechar o diálogo
        this.$emit("update:dialogVisible", false)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/style/main.scss";
</style>
