import axios from "axios"

const API_CREATE_USER = "http://www.api-plataforma.machiron.com.br"

const register = (email, password, re_password) => {
  return axios.post(`${API_CREATE_USER}/registry/users/`, {
    email,
    password,
    re_password,
  })
}

export default {
  register,
}
