import { getMapa } from "@/services/mapa"
import * as state from './state'

// export const setAltName = (name) => {
//   state
// }

export const fetchMapa = () => {
  getMapa()
    .then((res) => {
      state.mapaData.value = res?.data
    })
    .catch((err) => {
      console.error(err)
    })
}