<script setup>
import { defineProps, defineEmits } from 'vue'
import useGeneralStore from '@/stores/general'
import { deleteFinding } from '@/services/findingsDetails'
import { removeFinding } from '@/stores/findings/actions'

const props = defineProps({
	idFinding: null
})

const storeGeneral = useGeneralStore()

const emit = defineEmits(['deleteConfirm'])

const confirmDelete = () => {
	deleteFinding(props.idFinding)
	removeFinding(props.idFinding)
	emit('deleteConfirm', true)
	storeGeneral.toggleModalDelete(false)
}

</script>

<template>
  <div class="modal-delete">
		<div class="modal-delete__content">
			<span 
				class="modal-delete__close-button"
				@click="storeGeneral.toggleModalDelete(false)"
			>X</span>
			<h3 class="modal-delete__title">Excluir achado</h3>
			<p class="modal-delete__description">Deseja realmente excluir este achado?</p>
			<button 
				class="modal-delete__button-confirm"
				@click="confirmDelete"
			>Excluir</button>
			<button 
				class="modal-delete__button-cancel"
				@click="storeGeneral.toggleModalDelete(false)"
			>Cancelar</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import './modal_delete.scss'
</style>