//aqui só salva os valores
import { getFindingsDetails } from '@/services/findingsDetails'
import * as state from './state'
import { mapaData } from '../mapa/state'


export const fetchFindings = async (idExam) => { 
  try {
    const res = await getFindingsDetails(idExam)
    state.findingsDetails.value = JSON.parse(res?.data)
    const achados = state.findingsDetails.value

    const mapa = mapaData.value
    const idAlteracoesAnatomicas = Object.values(mapa)
      .filter(item => item.tipo_alteracao === 1)
      .map(item => item.id_alteracao); //mapeando os dados anatomicos baseado no mapa
    
      const idAlteracoesDentais = Object.values(mapa)
      .filter(item => item.tipo_alteracao === 0)
      .map(item => item.id_alteracao); //mapeando os dados dentais baseado no mapa

    const filteredAnatomico = achados.filter(item => idAlteracoesAnatomicas.includes(item.fields.id_alteracao)); //filtrando os dados anatomicos baseado no retorno da api
    const filteredDental = achados.filter(item => idAlteracoesDentais.includes(item.fields.id_alteracao)); //filtrando os dados dentais baseado no retorno da api

    const anatomicoDict = {}; //dict com os achados, por nome, e quantidade
    const dentalDict = {} //dict com os achados, por dente e achados

    filteredAnatomico.forEach(item => { 
      const idAlteracao = item.fields.id_alteracao
      const nmAlteracao = mapa.find(data => data.id_alteracao === idAlteracao).nm_alteracao
      if (anatomicoDict[nmAlteracao]) {
        anatomicoDict[nmAlteracao]++
      } else {
        anatomicoDict[nmAlteracao] = 1
      }
    })

    filteredDental.forEach(item => {
      const numDente = item.fields.num_dente
      const nmAlteracao = mapa.find(data => data.id_alteracao === item.fields.id_alteracao).nm_alteracao
      const achadoData = {
        num_dente: item.fields.num_dente,
        nm_alteracao: nmAlteracao,
        pk: item.pk,
        id_alteracao: item.fields.id_alteracao,
        observacao: item.fields.observacao,
        shortObs: true
      }
      
      if (!dentalDict[numDente]) {
        dentalDict[numDente] = [];
      }
      dentalDict[numDente].push(achadoData)
    })

    setAnatomicos(anatomicoDict)
    setDentais(dentalDict)

  } catch (error) {
    console.error(error)
  }
}

export const setTeethOut = (value) => {
  state.teethOut.value = value
}

export const setAnatomicos = (value) => {
  state.anatomicos.value = value 
}

export const setDentais = (value) => {
  state.dentais.value = value 
}

export const removeFinding = (pk) => {
  for (const key in state.dentais.value) {
    if (Array.isArray(state.dentais.value[key])) {
      //filtra o array para remover itens com pk informado
      state.dentais.value[key] = state.dentais.value[key].filter(item => item.pk !== pk)
      //remove o dente se o array ficar vazio
      if (state.dentais.value[key].length === 0) {
        delete state.dentais.value[key]
      }
    }
  }
}
