import * as state from './state'

export const setIsRequestLoad = (value) => {
  state.isRequestLoad.value = value
}

export const toggleModalDelete = (value) => {
  state.isModalDelete.value = value
}

export const toggleModalEdit = (value) => {
  state.isModalEdit.value = value
}

export const toggleModalObservation = (value) => {
  state.isModalObservation.value = value
}

export const showFindingOrder = () => {
  state.isFidingOrder.value = true
  state.isTeethOrder.value = false
}

export const showTeethOrder = () => {
  state.isTeethOrder.value = true
  state.isFidingOrder.value = false
}