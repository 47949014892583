<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        style="background-color: #122167"
        theme="dark"
        permanent
        width="236"
      >
        <div class="text-center">
          <img
            src="@/assets/logo-white.png"
            alt="MaChiron"
            style="width: 70%"
          />
        </div>
        <v-list color="transparent" class="ma-1 text-h6">
          <!-- <v-list-item
            prepend-icon="mdi-view-dashboard"
            @click="goToDashboard"
            title="Dashboard"
          ></v-list-item> -->
          <v-list-item
            prepend-icon="mdi-menu"
            rounded="shaped"
            @click="goToList"
            class="menu-item"
            ><span class="text-h6-base">Lista de exames</span></v-list-item
          >
          <v-list-item
            prepend-icon="mdi-upload"
            rounded="shaped"
            @click="goToUpload"
            class="menu-item"
            ><span class="text-h6-base">Upload exame</span></v-list-item
          >
        </v-list>

        <template v-slot:append>
          <div class="d-flex justify-center align-center flex-column pa-2 mx-2">
            <img
              src="@/assets/logo_fapesp.png"
              alt="Fapesp"
              style="width: 80%"
            />

            <img src="@/assets/logo_finep.png" alt="Finep" style="width: 70%" />
            <v-btn
              class="text-h6-base text-uppercase mt-4"
              @click="goToLogin"
              block
            >
              Sair
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </v-layout>
  </v-card>
</template>
<script>
  export default {
    name: "NavigationDrawer",
    methods: {
      goToDashboard() {
        this.$router.push("/dashboard")
      },
      goToList() {
        this.$router.push("/list")
      },
      goToUpload() {
        this.$router.push("/upload")
      },
      goToLogin() {
        localStorage.clear()
        this.$router.push("/")
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/style/main.scss";

  .menu-item :deep(.v-list-item__spacer) {
    width: 1rem!important;
  }
</style>
