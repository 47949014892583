import { getImages } from '@/services/images'
import * as state from './state'
  
export const setImage = (value) => {
  state.image.value = value
}

export const fetchImages = (examId) => {
    getImages(examId)
    .then((res) => {
      setImage(URL.createObjectURL(res?.data))
    })
    .catch(() => {
      console.log('error resquest')
    })
}

