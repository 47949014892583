import axios from "axios"

const getImages = (examId) => {
  const url = `https://www.api-plataforma.machiron.com.br/exam/${examId}/get_original_image/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "image/jpeg",
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob'
  }
  return axios.get(url, config, )
}

export { getImages }


