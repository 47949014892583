<template>
  <v-container id="particles-js">
    <div class="d-flex justify-end align-center">
      <p class="text-body-1-base ml-6">Não tem uma conta ainda?</p>
      <v-btn
        variant="plain"
        class="text-body-2-base"
        color="#122167"
        @click="goRegister"
      >
        Criar conta
      </v-btn>
    </div>
    <div class="d-flex justify-center align-center">
      <v-card
        width="380"
        height="400"
        elevation="20"
        class="pa-6 py-4 box-card"
      >
        <div class="mt-6 text-center">
          <p class="text-h4-base font-weight-bold">Login</p>
          <p class="text-body-1-base" style="color: #9e9e9e">
            Insira seu e-mail e senha
          </p>
        </div>
        <v-form ref="form" @submit.prevent="checkLogin" class="mt-5">
          <v-text-field
            label="E-mail"
            v-model="email"
            clearable
            variant="solo"
            required
            @change="checkEmail"
            class="custom-background"
            :class="{ error: emailError && emailTouched }"
          ></v-text-field>

          <v-text-field
            label="Senha"
            v-model="password"
            type="password"
            variant="solo"
            required
            @input="checkPassword"
            :class="{ error: passwordError && passwordTouched }"
          ></v-text-field>
          <v-label
            v-show="emailError && emailTouched"
            color="error"
            style="color: red"
            >{{ emailError }}</v-label
          >
          <v-label v-show="passwordError && passwordTouched" color="error">{{
            passwordError
          }}</v-label>
          <div class="d-flex align-center justify-space-between mt-2">
            <v-btn
              variant="plain"
              class="font-weight-medium text-body-2-base together"
              color="#122167"
              @click="goForgotPassword"
            >
              Esqueceu sua senha?
            </v-btn>
            <v-btn
              :disabled="!isValid"
              type="button"
              color="#122167"
              size="large"
              variant="flat"
              @click="checkLogin"
            >
              <p class="font-weight-bold text-white text-body-2-base">Login</p>
            </v-btn>
          </div>
          <snackbarNotification ref="alertComponent"></snackbarNotification>
        </v-form>
      </v-card>
    </div>
  </v-container>
</template>
<script>
  import axios from "axios"
  import snackbarNotification from "./snackbarNotification.vue"
  import { useGetUserData } from "./composables/useGetUserData"

  export default {
    name: "loginForm",
    components: {
      snackbarNotification,
    },
    data() {
      return {
        email: "",
        password: "",
        emailValid: true,
        passwordValid: true,
        passwordTouched: false,
        emailError: "",
        passwordError: "",
        emailTouched: false,
      }
    },
    mounted() {
      const particlesJS = window.particlesJS
      particlesJS("particles-js", {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#122167",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#c8581b",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      })
    },
    methods: {
      checkEmail() {
        // eslint-disable-next-line no-extra-semi
        ;/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(this.email)
          ? (this.emailValid = true)
          : (this.emailValid = false)

        this.emailTouched = true
      },
      checkUpperCase(str) {
        return /[A-Z]/.test(str) && /[0-9]/.test(str)
      },
      checkPassword() {
        const userName = this.email.split("@")[0]
        const passwordElement = document.getElementById("Password")

        if (passwordElement) {
          if (
            this.password.length >= 6 &&
            this.checkUpperCase(this.password) &&
            this.password !== userName
          ) {
            passwordElement.classList.remove("notValid")
            passwordElement.classList.add("valid")
            document.getElementById("passwordError").classList.add("disable")
            this.passwordValid = true
            this.passwordTouched = true
          } else if (this.passwordTouched) {
            passwordElement.classList.remove("valid")
            passwordElement.classList.add("notValid")
            document.getElementById("passwordError").classList.remove("disable")
            this.passwordValid = false
          }
        }
      },
      async checkLogin(e) {
        e.preventDefault()

        this.emailError = ""
        this.passwordError = ""

        // Função validate para validar os campos do formulário
        const isValid = await this.$refs.form.validate()

        if (isValid) {
          try {
            // Lógica para fazer login, obter token, etc.
            const response = await axios.post(
              "http://www.api-plataforma.machiron.com.br/auth/token/jwt/create/",
              {
                email: this.email,
                password: this.password,
              }
            )

            const token = response.data.access

            // Armazene o token JWT em localStorage
            localStorage.setItem("jwtToken", token)
            
            // Chamando composable para trazer dados do usuário
            await useGetUserData(token)
            

            this.$router.push("/list")
            this.$refs.alertComponent.showSuccess("Login successful!")
          } catch (error) {
            // Lidar com erros de login, como credenciais inválidas
            console.error("Erro ao fazer login:", error)

            // Defina as mensagens de erro
            if (error.response && error.response.status === 401) {
              // Se o status da resposta for 401 (Unauthorized)
              this.emailError = "E-mail ou senha inválidos."
              this.passwordError = "E-mail ou senha inválidos."
            } else {
              // Lidar com outros casos de erro, se necessário
              this.$refs.alertComponent.showError(
                "Falha no login. Por favor, verifique suas credenciais."
              )
            }
          }
        }
      },
      
      goForgotPassword() {
        this.$router.push("/forgot-password")
      },

      goRegister() {
        this.$router.push("/register")
      },
    },

    computed: {
      isValid() {
        return this.emailValid && this.passwordValid && this.email
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/style/main.scss";
  #particles-js {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .box-card {
    position: absolute;
    margin-top: 75%;
  }
  .together {
    margin: -3% !important;
  }
</style>
