import { computed } from 'vue'
import * as state from './state'

export const mapSexo = computed(() => {
  switch (state.examDetails.value.sexo) {
    case 1: 
      return 'Feminino'
    case 2: 
      return 'Masculino'
    default: 
      return 'Não Informado'
  }
})

export const mapTipoDenticao = computed(() => {
  switch (state.examDetails.value.tipo_denticao) {
    case 1:
      return "Dentição Permanente"
    case 2:
      return "Dentição Mista"
    case 3:
      return "Dentição Decídua"
    default:
      return "Desconhecido"
  }
})