<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div class="cmp-data-table">
    <snackbarNotification ref="snackbarRef" />
    <div class="cmp-data-table__wrap d-flex">
      <v-text-field
        v-model="search"
        label="Pesquisar"
        class="cmp-data-table__search-input mb-2"
      >
        <v-icon class="icon-search mx-2">mdi-magnify</v-icon>
      </v-text-field>
      <div class="d-flex pa-2">
        <v-btn @click="addExam" color="primary mx-6">Adicionar exame</v-btn>
      </div>
    </div>

    <v-data-table
      :page="page"
      :headers="headers"
      :items="items"
      :search="search"
      :sort-by="sortBy"
      :loading="loading"
      :items-per-page="itemsPerPage"
      class="elevation-1"
      @click:row="onRowClick"
    >
      <template v-slot:item.sg_status="{ item }">
        <v-chip :color="getChipColor(item.sg_status)">
          {{ item.sg_status }}
        </v-chip>
      </template>

      <template v-slot:item.dt_received="{ item }">
        {{ formatarData(item.dt_received) }}
      </template>

      <template v-slot:item.dt_modified="{ item }">
        {{ formatarData(item.dt_modified) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <v-icon
            name="co-reload"
            @click.stop="reloadItem(item.id_exam)"
            class="mx-2"
          />
          <v-icon name="hi-trash" @click.stop="deleteItem(item)" class="mx-2" />
        </div>
      </template>

      <template v-slot:no-data>
        <v-alert :value="true" color="error" icon="mdi-alert">
          Nenhum resultado
        </v-alert>
      </template>

      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisiblePages"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
          ></v-pagination>
        </div>
      </template>

      <template v-slot:header="{ header }">
        <div class="custom-header">
          <span @click="toggleSortOrder(header.value)">
            {{ header.text }}
            <v-icon v-if="isSortAsc(header.value)">mdi-chevron-up</v-icon>
            <v-icon v-else-if="isSortDesc(header.value)"
              >mdi-chevron-down</v-icon
            >
          </span>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">Deletar exame</v-card-title>
        <v-card-text>Deseja excluir o exame?</v-card-text>
        <v-card-actions class="justify-end">
          <v-btn variant="tonal" @click="deleteConfirmed" color="primary">
            Deletar
          </v-btn>
          <v-btn variant="tonal" @click="closeDialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import snackbarNotification from "./snackbarNotification.vue"
  import { format, addHours } from "date-fns"
  export default {
    props: {
      headers: {
        type: Array,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      sortBy: {
        type: Array,
        required: false,
      },
      itemsPerPage: {
        type: Number,
        required: false,
      },
    },
    components: {
      snackbarNotification,
    },
    data() {
      return {
        search: "",
        loading: true,
        page: 1,
        dialogReload: false,
        dialogDelete: false,
        examToDelete: null,
      }
    },
    mounted() {
      setTimeout(() => {
        this.loading = false
      }, 2000)
    },
    computed: {
      pageCount() {
        const totalItems = this.items.length
        const perPage = this.itemsPerPage || 10
        return Math.ceil(totalItems / perPage)
      },
      totalVisiblePages() {
        return Math.min(this.pageCount, 5)
      },
    },
    methods: {
      updateItemsPerPage(value) {
        this.$emit("update:itemsPerPage", parseInt(value, 10))
      },
      formatarData(data) {
        if (!data) return ""

        const dataObj = addHours(new Date(data), 3)

        const dataFormatada = format(dataObj, "dd/MM/yyyy")
        return dataFormatada
      },
      getChipColor(status) {
        switch (status) {
          case "Revisado":
            return "blue"
          case "Processado":
            return "green"
          case "Erro":
            return "red"
          case "Incompleto":
            return "orange"
          case "Em processamento":
            return "gray"
          default:
            return ""
        }
      },
      addExam() {
        this.$router.push("/upload")
      },
      onRowClick(event, item) {
        if (!event.target.role) {
          this.$emit("row-click", item)
        }
      },
      reloadItem(item) {
        const id = item
        const token = localStorage.getItem("jwtToken")
        fetch(
          `https://www.api-plataforma.machiron.com.br/exam/${id}/rerun_predictions`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Erro ao reprocessar o exame")
            }
            this.$refs.snackbarRef.showSuccess(
              "Exame reprocessado com sucesso!"
            )
            this.$emit("exam-deleted-successfully")
          })
          .catch(() => {
            this.$refs.snackbarRef.showError(
              "Erro ao reprocessar o exame, tente novamente"
            )
          })
          .finally(() => {
            this.dialogReload = false
          })
      },
      deleteItem(item) {
        this.examToDelete = item
        this.dialogDelete = true
      },
      deleteConfirmed() {
        const id = this.examToDelete.id_exam
        const token = localStorage.getItem("jwtToken")
        fetch(`https://www.api-plataforma.machiron.com.br/exam/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Erro ao deletar o exame")
            }
            this.$refs.snackbarRef.showSuccess("Exame deletado com sucesso!")
            this.$emit("exam-deleted-successfully")
          })
          .catch(() => {
            this.$refs.snackbarRef.showError(
              "Erro ao deletar o exame, tente novamente"
            )
          })
          .finally(() => {
            this.examToDelete = null
            this.dialogDelete = false
          })
      },

      closeDialog() {
        this.examToDelete = null
        this.dialogDelete = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/style/main.scss";
  // .elevation-1 {
  //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // }

  .cmp-data-table {
    displa
    &__wrap {
      display: flex;  
      justify-content: space-between;
      
    }

    .v-text-field {
      max-width: 24rem;
    }

    .v-field--variant-filled .v-field__overlay {
      background: rgb(224, 224, 224);
      position: relative;
    }

    .icon-search {
      position: absolute;
      font-size: 2rem;
      right: 1rem;
      top: 1rem;
      border-radius: 0;
    }

    
  }

  
</style>
