import { ref } from 'vue'

export const examDetails = ref({
  id_exam: '',
  sexo: '',
  sexo_corrigido: '',
  idade: '',
  idade_corrigido: '',
  tipo_denticao: '',
  tipo_denticao_corrigido: '',
  qualidade: '',
  user: ''
})
