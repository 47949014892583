<script setup>
import { ref, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import LoginForm from "@/components/loginForm.vue"
import MachironContainer from "@/components/machironContainer.vue"
import { useGetUserData } from "@/components/composables/useGetUserData"
import snackbarNotification from "@/components/snackbarNotification.vue"

const route = useRoute()
const router = useRouter()
const token = route.params.token

const snackbarRef = ref(null)

onMounted(async () => {
  if (token) {
    localStorage.setItem("jwtToken", token)
    
    try {
      const userData = await useGetUserData(token)
      if (userData) {
        router.push('/list');
      }
    } catch {
      snackbarRef.value.showError("Erro ao logar")
    }
  }
});

</script>

<template>
  <v-row no-gutters>
    <snackbarNotification ref="snackbarRef" />
    <v-col cols="4" md="5"><MachironContainer /></v-col>
    <v-col cols="8" md="7"><LoginForm /></v-col>
    <h3>token: {{ token }}</h3>
  </v-row>
</template>

<style lang="scss" scoped></style>
