import axios from "axios"

const getExamDetails = (examId) => {
  const url = `http://www.api-plataforma.machiron.com.br/pat/${examId}`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  return axios.get(url, config)
}

const postAlteracao = (requestData) => {
  const url = `http://www.api-plataforma.machiron.com.br/alt/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.post(url, requestData, config)
}

const changeTypeTeeth = (examId, requestData) => {
  const url = `http://www.api-plataforma.machiron.com.br/pat/${examId}`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.put(url, requestData, config)
}

export { getExamDetails, postAlteracao, changeTypeTeeth }