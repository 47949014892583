<script setup>
import { defineProps } from 'vue'
import assinatura from '@/assets/temp/assinatura.png'

const props = defineProps({
  isDownload: {
    type: Boolean,
    default: false
  },
})


</script>

<template>
  <div class="cmp-exam-head" :class="{ download: props.isDownload }">
    <div class="cmp-exam-head__text">
      <img 
        v-if="props.isDownload"
        :src="assinatura" 
        alt="Assinatura"
        class="cmp-exam-head__signature"
      >
      <span class="cmp-exam-head__name">Dr não informado</span>
      <span class="cmp-exam-head__code">CROSP: 1234</span>
    </div>
    <img
      src="@/assets/logo-papaiz.png"
      alt="Logo"
      class="cmp-exam-head__img"
    />
  </div>
</template>

<style scoped lang="scss">
  @import '_exam-head.scss';
</style>
