<template>
  <v-row no-gutters>
    <v-col cols="4" md="5"><machironContainer /></v-col>
    <v-col cols="8" md="7"><ForgotPasswordForm /></v-col>
  </v-row>
</template>

<script>
  import ForgotPasswordForm from '@/components/ForgotPasswordForm.vue';

  import machironContainer from "@/components/machironContainer.vue"
  export default {
    name: "ForgotPasswordPage",
    components: { ForgotPasswordForm, machironContainer },
  }
</script>

<style lang="scss" scoped>
  .container {
    display: grid;
    grid-template-rows: 100vh;
    grid-template-columns:
      [full-start] 1fr
      [center-start] repeat(
        10,
        [col-start] minmax(min-content, 14rem) [col-end]
      )
      [center-end] 1fr [full-end];
    margin: 0 auto;
  }
</style>
