<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { useRoute } from 'vue-router'
import useGeneralStore from '@/stores/general'
import { sendObservation } from '@/services/findingsDetails'
import snackbarNotification from '@/components/snackbarNotification.vue'

const props = defineProps({
	idFinding: null
})

const route = useRoute()
const emit = defineEmits()

const observation = ref()
const examId = route.params.id_exam
const snackbarRef = ref(null)

const storeGeneral = useGeneralStore()

const putObservation = async () => {
  try {
    await sendObservation(props.idFinding, observation.value, examId)
		emit('sendSuccess')
		storeGeneral.toggleModalObservation(false)
  } catch (error) {
		emit('sendError')
  }
}
</script>

<template>
  <div class="modal-observation">
		<snackbarNotification ref="snackbarRef" />
		<div class="modal-observation__content">
			<span 
				class="modal-observation__close-button"
				@click="storeGeneral.toggleModalObservation(false)"
			>X</span>
			<h3 class="modal-observation__title">Inserir Observação</h3>
			<input 
				type="text" 
				v-model="observation"
				placeholder="Inserir observação"
				class="modal-observation__input"
				@keydown.enter="putObservation"
			>
			<div>
				<button 
					class="modal-observation__button-confirm"
					@click="putObservation"
				>Salvar</button>
				<button 
					class="modal-observation__button-cancel"
					@click="storeGeneral.toggleModalObservation(false)"
				>Cancelar</button>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import './modal_observation.scss'
</style>