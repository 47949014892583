import {
  HiX,
  RiPencilFill,
  HiTrash,
  HiPlus,
  RiSubtractLine,
  CoReload,
} from 'oh-vue-icons/icons'

export const icons = [
  HiX,
  RiPencilFill,
  HiTrash,
  HiPlus,
  RiSubtractLine,
  CoReload,
]