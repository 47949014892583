import { createRouter, createWebHistory } from "vue-router"
import LoginPage from "@/views/LoginPage.vue"
import ForgotPasswordForm from "@/views/ForgotPasswordPage.vue"
import RegisterPage from "@/views/RegisterPage.vue"
import DashboardPage from "@/views/DashboardPage.vue"
import ListPage from "@/views/ListPage.vue"
import UploadPage from "@/views/UploadPage.vue"
import ExamDetailsPage from "@/views/ExamDetailsPage.vue"
import ErrorPage from "@/views/ErrorPage.vue"
import ExameDetails from "@/views/exam-details/ExamDetails.vue"

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginPage,
    meta: {
      title: "MaChiron | Login",
    },
  },
  {
    path: "/:token",
    name: "login-papaiz",
    component: LoginPage,
    meta: {
      title: "MaChiron | Login",
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterPage,
    meta: {
      title: "MaChiron | Registro",
    },
  },
  {
    path: "/forgot-password",
    name: "forgotpassword",
    component: ForgotPasswordForm,
    meta: {
      title: "MaChiron | Esqueci minha senha",
    },
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardPage,
    meta: {
      title: "MaChiron | Dashboard",
    },
  },
  {
    path: "/list",
    name: "list",
    component: ListPage,
    meta: {
      title: "MaChiron | Lista de exames",
    },
  },
  {
    path: "/upload",
    name: "upload",
    component: UploadPage,
    meta: {
      title: "MaChiron | Upload exame",
    },
  },
  {
    path: "/exam/:id_exam",
    name: "ExamDetailsPage",
    component: ExamDetailsPage,
    meta: {
      title: "MaChiron | Detalhes do exame",
    },
  },
  {
    path: "/exam-details/:id_exam",
    name: "ExameDetails",
    component: ExameDetails,
    meta: {
      title: "MaChiron | Detalhes do exame",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "ErrorPage",
    component: ErrorPage,
    meta: {
      title: "MaChiron | Error",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
