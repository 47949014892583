<template>
  <v-row>
    <v-col :cols="8">
      <div class="d-flex justify-start align-center">
        <span class="mx-6 mt-4 mb-4 text-h3-base font-weight-bold"
          >Panorâmica
        </span>
        <v-btn icon @click="teste(model)">
          <v-icon color="#122167">mdi-launch</v-icon>
        </v-btn>
      </div>
      <v-carousel
        v-model="model"
        hide-delimiters
        progress="#122167"
        class="zoom-carousel"
        ref="carousel"
      >
        <template v-slot:prev="{ props }">
          <v-btn
            density="comfortable"
            color="#122167"
            icon="mdi-chevron-left"
            @click="props.onClick"
          ></v-btn>
        </template>
        <template v-slot:next="{ props }">
          <v-btn
            density="comfortable"
            color="#122167"
            icon="mdi-chevron-right"
            @click="props.onClick"
          ></v-btn>
        </template>
        <v-carousel-item
          v-for="(image, index) in images"
          :key="index"
          ref="carouselItems"
        >
          <v-sheet height="100%" tile @click="openGallery(index)">
            <v-img
              :src="image.src"
              width="100%"
              height="100%"
              class="carousel-image"
            ></v-img>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-col>

    <v-col :cols="4">
      <div class="thumbnail-container">
        <v-img
          v-for="(image, index) in images"
          :key="index"
          :src="image.src"
          width="45%"
          alt="Thumbnail"
          @click="selectImage(index)"
          :class="{ 'selected-thumbnail': index === model }"
          style="margin-bottom: 10px; margin-left: 20px"
        ></v-img>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import "viewerjs/dist/viewer.css"
  import Viewer from "viewerjs"

  export default {
    props: {
      images: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        model: 0,
        viewer: null,
      }
    },
    beforeDestroy() {
      this.destroyViewer()
    },
    methods: {
      openGallery(index) {
        const carouselElement = this.$refs.carousel.$el

        this.destroyViewer()

        this.viewer = new Viewer(carouselElement, {
          inline: false,
          initialViewIndex: index,
          viewed() {
            this.viewer.zoomTo(0.8)
          },
        })

        this.viewer.show()
      },
      teste(index) {
        const imageSrc = this.images[index].src
        window.open(imageSrc, "_blank")
      },
      selectImage(index) {
        this.model = index
      },
      destroyViewer() {
        if (this.viewer) {
          this.viewer.destroy()
          this.viewer = null
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/style/main.scss";
  .zoom-carousel {
    cursor: zoom-in !important;
    overflow: hidden;
  }
  .carousel-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .thumbnail-container {
    margin-top: 100px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .selected-thumbnail {
    border: 2px solid red;
  }
</style>
